import React from "react";
import Layout from "../Components/layout";
import GetItems from "../Components/getItems";
import { Seo } from "../Components/seo";


export default function ItemSelection() {
  return (
    <Layout>
      <GetItems select={true} ignore={true}/>
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)